
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import mixinPaymentEach from "@/mixins/mixinPaymentEach";
  import global from "@/vuex/payment/global";
  import dialog from "@/vuex/dialog";
  import manager from "@/vuex/payment/manager";
  import header from "@/vuex/header";
  import PaymenteachTableHead from "@/components/paymenteach/table/Head.vue";
  import PaymenteachTableAttendance from "@/components/paymenteach/table/Attendance.vue";
  import PaymenteachTableInputallowance from "@/components/paymenteach/table/Inputallowance.vue";
  import PaymenteachTableAllowance from "@/components/paymenteach/table/Allowance.vue";
  import PaymenteachTableInputdeduction from "@/components/paymenteach/table/Inputdeduction.vue";
  import PaymenteachTableDeduction from "@/components/paymenteach/table/Deduction.vue";
  import PaymenteachTableInputotherallowance from "@/components/paymenteach/table/Inputotherallowance.vue";
  import PaymenteachTableOtherallowance from "@/components/paymenteach/table/Otherallowance.vue";
  import PaymenteachTableRealpay from "@/components/paymenteach/table/Realpay.vue";
  import PaymenteachTableBank from "@/components/paymenteach/table/Bank.vue";
  import PaymenteachTableInputcash from "@/components/paymenteach/table/Inputcash.vue";
  import PaymenteachTableCash from "@/components/paymenteach/table/Cash.vue";
  import PaymenteachTableOthers from "@/components/paymenteach/table/Others.vue";
  import PaymenteachTableInputcomment from "@/components/paymenteach/table/Inputcomment.vue";
  import PaymenteachTableComment from "@/components/paymenteach/table/Comment.vue";
  import PaymenteachDetailMain from "@/components/paymenteach/detail/Main.vue";

  @Component({
    components: {
      PaymenteachTableHead,
      PaymenteachTableAttendance,
      PaymenteachTableInputallowance,
      PaymenteachTableAllowance,
      PaymenteachTableInputdeduction,
      PaymenteachTableDeduction,
      PaymenteachTableInputotherallowance,
      PaymenteachTableOtherallowance,
      PaymenteachTableRealpay,
      PaymenteachTableBank,
      PaymenteachTableInputcash,
      PaymenteachTableCash,
      PaymenteachTableOthers,
      PaymenteachTableInputcomment,
      PaymenteachTableComment,
      PaymenteachDetailMain,
    },
  })
  export default class Main extends Mixins(mixinPaymentEach) {}
