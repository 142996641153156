
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import utilMixins from "@/mixins";
  import dialog from "@/vuex/dialog";
  import mixinPaymentEach from "@/mixins/mixinPaymentEach";
  import PaymenteachMonthchanger from "@/components/paymenteach/Monthchanger.vue";
  import CommonToggle from "@/components/common/Toggle.vue";
  import PaymenteachNormalmain from "@/components/paymenteach/Normalmain.vue";
  import PaymenteachListmain from "@/components/paymenteach/Listmain.vue";

  @Component({
    components: {
      PaymenteachMonthchanger,
      CommonToggle,
      PaymenteachNormalmain,
      PaymenteachListmain,
    },
  })
  export default class Payment_each extends Mixins(mixinPaymentEach) {
    get mode_value() {
      return this.mode;
    }
    set mode_value(val) {
      if (val) {
        this.mode = 1;
      } else {
        this.mode = 0;
      }
    }
  }
