
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinPaymentEach from '@/mixins/mixinPaymentEach';
import global from "@/vuex/payment/global";
import dialog from "@/vuex/dialog";
import manager from "@/vuex/payment/manager";
import header from "@/vuex/header";

@Component({
  components: {
  }
})
export default class Head extends Mixins(mixinPaymentEach) {
  @Prop() no_content_type: string;
  get monthstr() {
    if (this.payment_info)
      return util.formatDate(util.date2obj(this.payment_info.paid_on), 'yyyy年mm月dd日');
    else
      return util.formatDate(this.dateobj, 'yyyy年mm月')
  }

  prev_month() {
    var dateobj = util.date2obj(this.date+"-01");
    dateobj.setMonth(dateobj.getMonth() - 1);
    this.date = util.formatDate(dateobj, 'yyyy-mm');
  }
  next_month() {
    var dateobj = util.date2obj(this.date+"-01");
    dateobj.setMonth(dateobj.getMonth() + 1);
    this.date = util.formatDate(dateobj, 'yyyy-mm');
  }
}
