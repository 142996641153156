
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import mixinPaymentEach from "@/mixins/mixinPaymentEach";
  import global from "@/vuex/payment/global";
  import dialog from "@/vuex/dialog";
  import manager from "@/vuex/payment/manager";
  import header from "@/vuex/header";
  import CommonProgress from "@/components/common/Progress.vue";
  import PaymenteachTableNocontentlist from "@/components/paymenteach/table/Nocontentlist.vue";
  import PaymenteachList from "@/components/paymenteach/List.vue";

  @Component({
    components: {
      CommonProgress,
      PaymenteachTableNocontentlist,
      PaymenteachList,
    },
  })
  export default class Main extends Mixins(mixinPaymentEach) {}
