
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinPaymentEach from '@/mixins/mixinPaymentEach';
import global from "@/vuex/payment/global";
import dialog from "@/vuex/dialog";
import manager from "@/vuex/payment/manager";
import header from "@/vuex/header";

@Component({
  components: {
  }
})
export default class Main extends Mixins(mixinPaymentEach) {
  get allowance_padding() {
    var remain = 19;
    remain -= 6;//基本であるもの
    if (this.employee.over_type == 2 && (this.show_zero || this.each_payment.deemover_pay != 0)) remain--;

    if (!this.show_zero) {
      if (this.each_payment.hol_pay == 0) remain++;
      if (this.each_payment.mid_pay == 0) remain++;
      if (this.each_payment.over_pay == 0) remain++;
      if (this.each_payment.paid_hol_pay == 0) remain++;
      if (this.each_payment.carfare_pay == 0) remain++;
    }

    this.saved_payment_allowances.forEach(payment_allowance => {
      if (this.each_payment.each_payment_allowances[payment_allowance.id] && (this.show_zero || this.each_payment.each_payment_allowances[payment_allowance.id].money != 0)) remain--;
    });
    return Math.max(0, remain);
  }
}
