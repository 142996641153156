
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import mixinPaymentEach from "@/mixins/mixinPaymentEach";
  import global from "@/vuex/payment/global";
  import dialog from "@/vuex/dialog";
  import manager from "@/vuex/payment/manager";
  import header from "@/vuex/header";
  import CommonProgress from "@/components/common/Progress.vue";
  import PaymenteachHead from "@/components/paymenteach/Head.vue";
  import PaymenteachTableNocontent from "@/components/paymenteach/table/Nocontent.vue";
  import PaymenteachNormal from "@/components/paymenteach/Normal.vue";

  @Component({
    components: {
      CommonProgress,
      PaymenteachHead,
      PaymenteachTableNocontent,
      PaymenteachNormal,
    },
  })
  export default class Main extends Mixins(mixinPaymentEach) {}
