
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import mixinPaymentEach from "@/mixins/mixinPaymentEach";
  import dialog from "@/vuex/dialog";
  import UtilPopup from "@/components/util/Popup.vue";

  @Component({
    components: {
      UtilPopup,
    },
  })
  export default class Main extends Mixins(mixinPaymentEach) {
    get is_display() {
      return this.m.is_open_detail_dialog;
    }
    set is_display(val: boolean) {
      this.m.setIsOpenDetailDialog(val);
    }
  }
