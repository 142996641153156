
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import mixinPaymentEach from "@/mixins/mixinPaymentEach";
  import global from "@/vuex/payment/global";
  import dialog from "@/vuex/dialog";
  import manager from "@/vuex/payment/manager";
  import header from "@/vuex/header";

  @Component({
    components: {},
  })
  export default class Main extends Mixins(mixinPaymentEach) {
    get deduction_padding() {
      let remain = 20;
      remain -= 8; //基本であるもの
      if (this.each_payment.is_valid_fixed_tax_reduction) remain--;

      if (!this.show_zero && this.each_payment.res_tax == 0) remain++;

      this.saved_payment_deductions.forEach((payment_deduction) => {
        if (
          this.each_payment.each_payment_deductions[payment_deduction.id] &&
          (this.show_zero ||
            this.each_payment.each_payment_deductions[payment_deduction.id].money != 0)
        )
          remain--;
      });
      return Math.max(0, remain);
    }
  }
