
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import mixinPaymentEach from "@/mixins/mixinPaymentEach";
  import global from "@/vuex/payment/global";
  import dialog from "@/vuex/dialog";
  import manager from "@/vuex/payment/manager";
  import header from "@/vuex/header";
  import CommonButton from "@/components/common/Button.vue";

  @Component({
    components: {
      CommonButton,
    },
  })
  export default class Head extends Mixins(mixinPaymentEach) {
    @Watch("is_edit")
    on_change_is_edit(newval, oldval) {
      if (newval && !oldval) {
        this.tmp_each_payment = {
          each_payment_allowances: util.deep_copy(this.each_payment.each_payment_allowances),
          each_payment_deductions: util.deep_copy(this.each_payment.each_payment_deductions),
          each_payment_other_allowances: util.deep_copy(
            this.each_payment.each_payment_other_allowances,
          ),

          reg_pay: this.each_payment.reg_pay,
          hol_pay: this.each_payment.hol_pay,
          mid_pay: this.each_payment.mid_pay,
          over_pay: this.each_payment.over_pay,
          deemover_pay: this.each_payment.deemover_pay,
          paid_hol_pay: this.each_payment.paid_hol_pay,
          carfare_pay: this.each_payment.carfare_pay,

          health_ins: this.each_payment.health_ins,
          care_ins: this.each_payment.care_ins,
          pension_ins: this.each_payment.pension_ins,
          emp_ins: this.each_payment.emp_ins,
          social_ins_adj: this.each_payment.social_ins_adj,
          res_tax: this.each_payment.res_tax,
          income_tax: this.each_payment.income_tax,
          fixed_tax_reduction: this.each_payment.is_valid_fixed_tax_reduction
            ? this.each_payment.fixed_tax_reduction
            : null,

          paid_cash: this.each_payment.paid_cash,

          yearend_adj: this.each_payment.yearend_adj,
          settlement: this.each_payment.settlement,

          comment: this.each_payment.comment,
        };
      }
    }

    prev_employee() {
      this.employee_id = this.prev_employee_id;
    }
    next_employee() {
      this.employee_id = this.next_employee_id;
    }

    edit_each_payment() {
      this.m.editEachPayment();
    }
    update_each_payment() {
      this.m.updateEachPayment({
        yearmonth: this.date,
        employee_id: this.employee_id,
        employee: this.employee,
        saved_payment_allowances: this.saved_payment_allowances,
        saved_payment_deductions: this.saved_payment_deductions,
        saved_payment_other_allowances: this.saved_payment_other_allowances,
      });
    }

    get monthstr() {
      return util.formatDate(util.date2obj(this.payment_info.paid_on), "yyyy年mm月dd日");
    }

    prev_month() {
      const dateobj = util.date2obj(`${this.date}-01`);
      dateobj.setMonth(dateobj.getMonth() - 1);
      this.date = util.formatDate(dateobj, "yyyy-mm");
    }
    next_month() {
      const dateobj = util.date2obj(`${this.date}-01`);
      dateobj.setMonth(dateobj.getMonth() + 1);
      this.date = util.formatDate(dateobj, "yyyy-mm");
    }

    get is_edit() {
      return this.m.edit_each_payment_flag;
    }
  }
