import { Component, Vue, Mixins, Watch } from "vue-property-decorator";
import monthly from "@/vuex/payment_each/monthly";
import global from "@/vuex/payment_each/global";
import * as util from "@/util";
import utilMixins from "@/mixins";
import manager from "@/vuex/payment_each/manager";
import { EmployeeHeaderData, BranchHeaderData } from "@/typesold/header";

@Component
export default class mixinPaymentEach extends Mixins(utilMixins) {
  get is_set_global(): boolean {
    return global.data[this.date] && global.data[this.date].is_set;
  }
  get is_set_monthly(): boolean {
    return (
      monthly.data[this.date] &&
      monthly.data[this.date][this.employee_id] &&
      monthly.data[this.date][this.employee_id].is_set
    );
  }

  get is_valid_global(): boolean {
    return global.data[this.date] && global.data[this.date].is_valid;
  }
  get is_valid_monthly(): boolean {
    return (
      monthly.data[this.date] &&
      monthly.data[this.date][this.employee_id] &&
      monthly.data[this.date][this.employee_id].is_valid
    );
  }

  async get_global() {
    await global.fetch({ yearmonth: this.date });
  }
  async get_monthly() {
    await monthly.fetch({
      yearmonth: this.date,
      branch_id: this.viewing_branch,
      employee_id: this.employee_id,
      payment_info_id: this.global.payment_info.id,
    });
  }

  get global() {
    if (!this.is_set_global || !this.is_valid_global) this.get_global();
    if (this.is_set_global) return global.data[this.date];
  }
  get monthly() {
    if (!this.is_set_global || !this.is_valid_global)
      this.get_global(); //get_monthlyでglobalを使用するため
    else if (!this.is_set_monthly || !this.is_valid_monthly) this.get_monthly();
    if (this.is_set_monthly) return monthly.data[this.date][this.employee_id];
  }

  get employee_id() {
    if (this.is_manager) {
      return this.query.employee_id;
    } else {
      return Number(this.s_employee_id);
    }
  }
  set employee_id(val) {
    this.query_push = { employee_id: val };
  }

  get company() {
    return this.global.company;
  }
  get branch() {
    return this.monthly.branch;
  }
  get employee() {
    return this.monthly.employee;
  }
  get payment_info() {
    return this.global.payment_info;
  }

  get each_attendance() {
    return this.monthly.each_attendance;
  }
  get each_payment() {
    return this.monthly.each_payment;
  }

  get saved_payment_allowances() {
    return this.global.saved_payment_allowances;
  }
  get saved_payment_deductions() {
    return this.global.saved_payment_deductions;
  }
  get saved_payment_other_allowances() {
    return this.global.saved_payment_other_allowances;
  }

  get bank1_name() {
    return this.monthly.bank1_name;
  }
  get bank2_name() {
    return this.monthly.bank2_name;
  }

  //複数componentにInputが分かれているので、Mixinに統合した
  get tmp_each_payment() {
    return this.m.each_payment;
  }
  set tmp_each_payment(val) {
    this.m.setEachPayment({ each_payment_object: val });
  }
  change_each_payment_allowance(id: number, val) {
    this.m.setEachPaymentAllowance({ id, val });
  }
  change_each_payment_deduction(id: number, val) {
    this.m.setEachPaymentDeduction({ id, val });
  }
  change_each_payment_other_allowance(id: number, val) {
    this.m.setEachPaymentOtherAllowance({ id, val });
  }

  get viewing_branch() {
    var branch_id = this.query.viewing_branch;
    if (branch_id === undefined) {
      branch_id = Number(util.getLocalStorage("branch_id"));
      this.query = { viewing_branch: branch_id };
    }
    return branch_id;
  }
  set viewing_branch(val) {
    this.query = { viewing_branch: val };
  }

  get tab() {
    var tab = this.query.payment_tab;
    if (tab === undefined) {
      tab = "all";
      this.query = { payment_tab: tab };
    }
    return tab;
  }
  set tab(val) {
    this.query_push = { payment_tab: val };
  }

  get m() {
    return manager;
  }

  get todayobj() {
    return new Date();
  }

  get dateobj() {
    return util.date2obj(this.date + "-01");
  }
  get date() {
    var date = this.query.payment_date;
    if (date === undefined) {
      date = util.formatDate(new Date(), "yyyy-mm");
      this.query = { payment_date: date };
    }
    return date;
  }
  set date(val) {
    if (val != this.date) {
      this.query_push = { payment_date: val };
    }
  }

  get lock_type() {
    return this.monthly.lock_type;
  }
  get prev_employee_id() {
    return this.monthly.prev_employee_id;
  }
  get next_employee_id() {
    return this.monthly.next_employee_id;
  }

  get editable_payment() {
    let header = this.header as BranchHeaderData;
    return this.s_auth == 2 || (this.s_auth == 1 && header.auth_payment == 2);
  }
  get show_zero() {
    let header = this.header as EmployeeHeaderData;
    return this.is_manager || header.show_zero;
  }

  get show_custom() {
    return this.s_auth != 0;
  }

  get mode() {
    if (this.is_manager || !this.is_sp) {
      return 0;
    }
    return this.m.mode;
  }
  set mode(val) {
    util.setLocalStorage("payment_each_mode", val);
    manager.setMode(val);
  }
}
