
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import mixinPaymentEach from "@/mixins/mixinPaymentEach";
  import global from "@/vuex/payment/global";
  import dialog from "@/vuex/dialog";
  import manager from "@/vuex/payment/manager";
  import header from "@/vuex/header";
  import CommonText from "@/components/common/Text.vue";
  import CommonAllowance from "@/components/common/Allowance.vue";

  @Component({
    components: {
      CommonText,
      CommonAllowance,
    },
  })
  export default class Main extends Mixins(mixinPaymentEach) {
    get deduction_padding() {
      let remain = 20;
      remain -= 8; //基本であるもの
      this.saved_payment_deductions.forEach((payment_deduction) => {
        if (this.each_payment.each_payment_deductions[payment_deduction.id]) remain--;
      });
      return Math.max(0, remain);
    }
  }
