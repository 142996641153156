
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinPaymentEach from '@/mixins/mixinPaymentEach';
import global from "@/vuex/payment/global";
import dialog from "@/vuex/dialog";
import manager from "@/vuex/payment/manager";
import header from "@/vuex/header";
import CommonTextarea from "@/components/common/Textarea.vue";

@Component({
  components: {
    CommonTextarea,
  }
})
export default class Main extends Mixins(mixinPaymentEach) {
}
